<template>
  <v-container fluid fill-height     class="background-template"
>
    <v-layout  style="    z-index: 1000;
    justify-content: center;
    height: 95vh;
    align-items: center;"
    >
      <v-container xs12 sm8 md4 style="width:100% ; max-width:500px">
        <v-card class="elevation-12">
          <v-toolbar class="toolbar-login">
            <v-toolbar-title>
              <img :src="Logo" alt=""/>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="formLogin"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                prepend-icon="mdi-account"
                v-model="form.username"
                name="username"
                label="Username"
                type="text"
                placeholder="username"
                required
                :rules="userNameRules"
              ></v-text-field>

              <v-text-field
                prepend-icon="mdi-lock"
                v-model="form.password"
                name="password"
                label="Password"
                type="password"
                placeholder="password"
                required
                :rules="passwordRules"
              ></v-text-field>

              <v-alert
                v-if="message.show"
                variant="outlined"
                type="error"
                density="compact"
              >
                {{ message.text }}
              </v-alert>

              <v-btn
              class="mt-4"
              color="primary"
              value="log in"
              style="width: 100%;"
              @click="validate"
              :loading="loading"
              :disabled="!valid"
              >Login</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
// import Logo from '@/assets/logo.svg';
import Logo from '@/assets/logo_sandia_white_ix0lrt.webp';
import API from '@/lib/Api';

export default {
  name: 'AuthView',
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      message: {
        show: false,
        text: '',
      },
      valid: true,
      loading: false,
      required: (v) => !!v || 'This field is required',
      Logo,
      userNameRules: [
        (v) => !!v || 'Username is required',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length >= 4) || 'Password must be more than 4 characters',
      ],
    };
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.formLogin.validate();
      this.valid = !valid;
      if (valid) {
        this.login();
      }
      this.message.show = false;
    },
    async login() {
      this.loading = true;
      const result = await API.post('/user/login', this.form);
      if (!result.success) {
        console.log(result?.message ?? 'An error ocurred');
        this.message.show = true;
        this.message.text = result.message;
        this.valid = true;
        return;
      }
      this.message.show = false;
      this.loading = false;
      localStorage.setItem('auth', JSON.stringify(result.data));
      this.$router.push({ path: '/categories' });
    },
  },
};
</script>
<style scoped>
.background-template {
    background-color: white;
    height: 100vh;
}

.toolbar-login{
      background-color: #222222 !important;
    text-align: center;
}
</style>
