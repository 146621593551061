<template>
  <system-template>
    <bread-crumb :items="itemsBreadCrumb"></bread-crumb>
    <div>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" xs="12" class="py-5">
            <!-- <ckeditor :editor="editor"
            v-model:content="contentArticle.body"
            :config="editorConfig"></ckeditor> -->
            <!-- <pre>{{contentArticle.body}}</pre> -->
            <QuillEditor
              toolbar="essential"
              theme="snow"
              v-model:content="contentArticle.body"
              @editorChange="updatedEditor"
              @blur="updatedEditor"
              contentType="html"
              style="min-height: 300px;"
              ref="editor"
            />
            <!-- <div style="height: 150px; width: 100%;">asdfasdf</div> -->
          </v-col>
        </v-row>
        <v-row style="margin-top: 50px;">
          <!-- <h3>Enable/Disable Request Editor</h3> -->
          <br/>
          <br/>
          <!-- <pre>{{contentArticleCode}}</pre> -->
          <br/>
          <v-checkbox
            v-model="contentArticleCode.active"
            label="Enable/Disable Request Editor"
            @click="handleEnableDisableCode"
          ></v-checkbox>
        </v-row>
        <v-row v-if="contentArticleCode.active">
          <!-- <pre>{{contentArticleCode}}</pre> -->
          <request-builder v-model="contentArticleCode" mode="edit"></request-builder>
        </v-row>
        <v-row>
          <v-btn
            class="mt-2"
            color="primary"
            value="save"
            @click="saveContent"
            :loading="loading"
            >Save</v-btn
          >
        </v-row>
      </v-container>
    </div>
  </system-template>
</template>
<script>
import SystemTemplate from '@/templates/SystemTemplate.vue';
import RequestBuilder from '@/components/RequestBuilder.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import API from '@/lib/Api';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

require('brace/theme/monokai');
require('brace/mode/javascript');

export default {
  name: 'ContentView',
  components: {
    SystemTemplate,
    RequestBuilder,
    BreadCrumb,
    QuillEditor,
  },
  props: ['idCategory', 'idSubcategory', 'idArticle'],
  data: () => ({
    // editor: ClassicEditor,
    editorData: '<p>Content of the editor.</p>',
    editorConfig: {
      // The configuration of the editor.
    },
    itemsBreadCrumb: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Categories',
        disabled: false,
        href: '/categories',
      },
      {
        text: 'Subcategories',
        disabled: false,
        href: '',
      },
      {
        text: 'Articles',
        disabled: false,
        href: '',
      },
      {
        text: 'Content',
        disabled: true,
        href: '#',
      },
    ],
    loading: false,
    contentArticle: {
      body: '',
    },
    contentArticleCode: {
      header: [
        {
          key: 'Content-Type',
          value: 'application/json',
          readonly: true,
          required: true,
          active: true,
        },
      ],
      protocol: 'GET',
      url: '',
      body: '',
      active: true,
    },
    items: ['GET', 'POST', 'PUT', 'DELETE'],
    value: null,
    tabCode: 1,

  }),
  watch: {},
  methods: {
    handleEnableDisableCode() {
      this.contentArticleCode.active = Boolean(!this.contentArticleCode.active);
    },
    addHeader(header) {
      const { headers } = this;
      const lastItem = headers?.[headers.length - 1];
      if (
        (header.key.length > 0 && lastItem?.value?.length === 0)
        || (header.value.length > 0 && lastItem?.key?.length === 0)
      ) {
        const newHeader = {
          key: '',
          value: '',
        };
        const filteredItems = this.headers.filter(
          (el) => el.value.trim().length > 0,
        );
        this.headers = filteredItems;
        this.headers.push(newHeader);
      }
    },
    updatedEditor() {
      const { editor } = this.$refs;
      const text = editor.getHTML();
      this.contentArticle.body = text;
    },
    async getArticle() {
      try {
        const { data: { contents: [content] } } = await API.get(`/article/${this.idArticle}`);
        this.contentArticle = content ?? {};
        // this.$refs.editor.innerHTML = content.body;
        const { editor } = this.$refs;
        editor.setHTML(content.body);
        // debugger;
        const { codes } = content;
        const { codes: [code] } = content;
        if (Array.isArray(codes) && codes.length > 0) {
          this.contentArticleCode = code;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCategory() {
      try {
        const { data: { contents: [content] } } = await API.get(`/category/${this.idCategory}`);
        this.contentArticle = content ?? {};
        const { editor } = this.$refs;
        editor.setHTML(content.body);
        const { codes } = content;
        const { codes: [code] } = content;
        if (Array.isArray(codes) && codes.length > 0) {
          this.contentArticleCode = code;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveContent() {
      const { editor } = this.$refs;
      const text = editor.getHTML();
      this.contentArticle.body = text;
      this.loading = true;
      if (this.idArticle) {
        this.contentArticle.parentId = this.idArticle;
        this.contentArticle.parentType = 'article';
      } else {
        this.contentArticle.parentId = this.idCategory;
        this.contentArticle.parentType = 'category';
      }

      const result = await API.post('/content', this.contentArticle);
      if (!result.success) {
        console.log(result?.message ?? 'An error ocurred');
        this.$toast.error(
          result?.message ?? 'An error ocurred',
          {
            position: 'top',
          },
        );
        return;
      }
      this.loading = false;
      const success = await this.saveCode(result);
      if (success) {
        if (this.idArticle) {
          this.$router.push({ path: `/category/${this.idCategory}/subcategory/${this.idSubcategory}/articles` });
        } else {
          this.$router.push({ path: '/categories' });
        }
      }
      this.$toast.success(
        'Content updated successfully',
        {
          position: 'top',
        },
      );
    },
    async saveCode(resultContent) {
      const { data } = resultContent;
      this.loading = true;
      this.contentArticleCode.contentId = (data?.data) ? data.data.id : data.id;
      const result = await API.post('/code', this.contentArticleCode);
      if (!result.success) {
        console.log(result?.message ?? 'An error ocurred');
        this.$toast.error(
          result?.message ?? 'An error ocurred',
          {
            position: 'top',
          },
        );
        return false;
      }
      this.contentArticleCode = result.data;
      this.loading = false;
      return true;
    },
  },
  async mounted() {
    const index = this.itemsBreadCrumb.findIndex((e) => e.text === 'Subcategories');
    const index2 = this.itemsBreadCrumb.findIndex((e) => e.text === 'Articles');
    if (this.idArticle) {
      await this.getArticle();
      this.itemsBreadCrumb[index].href = `/category/${this.idCategory}/subcategories`;
      this.itemsBreadCrumb[index2].href = `/category/${this.idCategory}/subcategory/${this.idSubcategory}/articles`;
    } else {
      this.getCategory();
      this.itemsBreadCrumb.splice(index, 1);
      this.itemsBreadCrumb.splice(index2 - 1, 1);
    }
  },
};
</script>
<style scoped>
</style>
