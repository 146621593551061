<template>
  <!-- <v-app> -->
    <!-- <v-main> -->
      <div class="add-margin-bottom">
        <router-view/>
      </div>
    <!-- </v-main> -->
  <!-- </v-app> -->
</template>

<script>

export default {
  name: 'App',

  data: () => ({
  }),
  watch: {
    $route(to) {
      document.title = `SANDIA | ${to.name}`;
    },
  },
};
</script>

<style lang="scss">
  * {
    box-sizing: border-box;
  }
</style>
<style lang="scss" scoped>
  .add-margin-bottom {
    margin-bottom: 150px;
  }
</style>
