<template>
  <v-container fluid class="request-builder-container">
    <!-- {{mode}} -->
    <!-- <pre>{{requestObj}}</pre> -->
    <!-- <pre>{{props}}</pre> -->
    <!-- <pre>{{modelValue}}</pre> -->
    <!-- <pre>{{value}} </pre> -->
    <v-form>
      <v-row no-gutters>
        <v-col cols="12" sm="4" lg="4">
          <v-autocomplete
            v-model="requestObj.protocol"
            :items="methods"
            dense
            filled
            label="Method"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="7" lg="7">
          <v-text-field
            label="Url"
            v-model="requestObj.url"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" lg="1">
          <v-btn
            class="send-button elevation-1"
            style="    min-width: 70px;    width: 100%;    height: 55px;"
            variant="contained-flat"
            block
            :disabled="sending"
            @click.prevent="handleSendButton"
            :loading="sending">
              {{sending ? '...' : 'Send'}}
            </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" class="py-5">
            <v-tabs
              v-model="tab"
              background-color="white"
            >
              <v-tab value="headers">Headers</v-tab>
              <v-tab value="body">Body</v-tab>
              <!-- <v-tab value="variables">Variables</v-tab> -->
            </v-tabs>

            <v-window v-model="tab">
              <v-window-item value="headers">
                <!-- // Headers Container -->
                 <!-- <v-checkbox
                    v-model="automaticAuthHeader"
                    label="Automatic Auth Header"
                  ></v-checkbox> -->

                  <!-- <v-btn
                    class="outline elevation-1 my-5"
                    variant="contained-flat"
                    color="secondary"
                    :disabled="sending"
                    @click.prevent="generateAuthHeader"
                    >
                      Generate Auth Headers
                  </v-btn> -->
                <v-table>
                  <thead>
                    <tr>
                      <th v-if="mode !== 'view'" class="text-left readonlyheader">Read-only</th>
                      <th v-if="mode !== 'view'" class="text-left readonlyheader">Required</th>
                      <th class="text-left">Key</th>
                      <th class="text-left">Value</th>
                      <th class="text-left readonlyheader">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <pre>{{requestObj.header}}</pre> -->
                    <tr v-for="(header, index) in displayHeaders" :key="header.uuid">
                      <td  v-if="mode !== 'view'">
                        <v-checkbox
                          v-model="header.readonly"
                          :disabled="mode === 'view'"
                        ></v-checkbox>
                      </td>
                      <td  v-if="mode !== 'view'">
                        <v-checkbox
                          v-model="header.required"
                          :disabled="mode === 'view'"
                        ></v-checkbox>
                      </td>
                      <td>
                        <v-text-field
                          label="Key"
                          v-model="header.key"
                          :disabled="mode === 'view' && (header.readonly || header.required)"
                          hide-details="auto"
                          class="my-3"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          label="Value"
                          v-model="header.value"
                          :disabled="mode === 'view' && header.readonly"
                          hide-details="auto"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-tooltip anchor="bottom" v-if="header.required !== true">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              class="ma-1"
                              color="primary"
                              @click="handleDeleteHeader(index)"
                              icon
                              v-bind="props"
                              size="x-small"
                            >
                              <v-icon size="x-small"> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete Header</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
                <v-row>
                  <v-col>
                    <v-btn
                      class="mt-2"
                      color="primary"
                      value="Add Header"
                      @click="addHeader"
                      >Add Header</v-btn>
                  </v-col>
                </v-row>
              </v-window-item>

              <v-window-item value="body" class="editor-container">
                <!-- // Body Container -->
                <!-- <v-container> -->
                  <!-- <v-row> @init="init" -->
                    <!-- <pre style="color:white;">{{requestObj.body}}</pre> -->
                    <code-editor
                        v-model="requestObj.body"
                        lang="json"
                        theme="monokai"
                        style="height: 500px"
                        ref="ceditor"
                        v-if="false"
                      />
                      <json-editor
                        :value="requestObj.body"
                        @input="updateRequestObj"
                      ></json-editor>
                  <!-- </v-row> -->
                <!-- </v-container> -->
              </v-window-item>

              <v-window-item value="variables">
                <!-- // Headers Container -->
                <v-table>
                  <thead>
                    <tr>
                      <th v-if="false && mode !== 'view'"
                      class="text-left readonlyheader">Read-only</th>
                      <th v-if="false && mode !== 'view'"
                      class="text-left readonlyheader">Required</th>
                      <th class="text-left">Key</th>
                      <th class="text-left">Value</th>
                      <th class="text-left readonlyheader">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <pre>{{requestObj.header}}</pre> -->
                    <tr v-for="(variable, index) in displayVariables" :key="variable.uuid">
                      <td  v-if="false && mode !== 'view'">
                        <v-checkbox
                          v-model="variable.readonly"

                        ></v-checkbox>
                      </td>
                      <td  v-if="false && mode !== 'view'">
                        <v-checkbox
                          v-model="variable.required"
                          :disabled="true"
                        ></v-checkbox>
                      </td>
                      <td>
                        <v-text-field
                          label="Key"
                          v-model="variable.key"
                          :disabled="true"
                          hide-details="auto"
                          class="my-3"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          label="Value"
                          v-model="variable.value"
                          :disabled="mode === 'view' && variable.readonly"
                          hide-details="auto"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-tooltip anchor="bottom" v-if="false && variable.required !== true">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              class="ma-1"
                              color="primary"
                              @click="handleDeleteVariable(index)"
                              icon
                              v-bind="props"
                              size="x-small"
                            >
                              <v-icon size="x-small"> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete Header</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
                <v-row v-if="false">
                  <v-col>
                    <v-btn
                      class="mt-2"
                      color="primary"
                      value="Add Header"
                      @click="addHeader"
                      >Add Header</v-btn>
                  </v-col>
                </v-row>
              </v-window-item>

            </v-window>
        </v-col>
      </v-row>

      <v-row v-if="showResponse">
        <v-col>
          <h3>Response</h3>
          <!-- <pre>{{response}}</pre> -->
        </v-col>
      </v-row>
      <v-row class="my-5" v-if="showResponse">
        <v-col>
          <div class="d-flex my-2">
          <div class="me-3">
            Status: <span data-status>{{response.status}}</span>
          </div>
          <div class="me-3">
            Time: <span data-time>{{response.time}}</span>ms
          </div>
          <div class="me-3">
            Size: <span data-size>{{response.size}}</span>
          </div>
      </div>

        </v-col>
      </v-row>
      <v-row v-if="showResponse">
        <v-col>
          <v-tabs
            v-model="tabResponse"
            background-color="white"
            class="my-5"
          >
            <v-tab value="headers">Headers</v-tab>
            <v-tab value="body">Body</v-tab>
          </v-tabs>

          <v-window v-model="tabResponse" class="my-5">
            <v-window-item value="headers">
              <!-- <div class="header-detail-container">

                <template v-for="header in response.headers" :key="header.uuid"
              >
                <div class="response-header-key">{{header.key}}</div>
                <div class="response-header-value">{{header.value}}</div>
              </template>
              </div> -->
              <v-table>
                  <thead>
                    <tr>
                      <th class="text-left">Key</th>
                      <th class="text-left">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(header) in response.headers" :key="header.uuid">
                      <td>
                        <v-text-field
                          label="Key"
                          v-model="header.key"
                          disabled
                          hide-details="auto"
                          class="my-3"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          label="Value"
                          v-model="header.value"
                          disabled
                          hide-details="auto"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
            </v-window-item>
            <v-window-item value="body" class="editor-container">
              <!-- <pre style="color:white;">{{this.response.body}}</pre> -->
              <!-- <pre style="color:white;">{{response_body}}</pre> -->
              <code-editor
                v-model="response.body"
                lang="json"
                theme="monokai"
                style="height: 500px"
                v-if="false"
              />
              <json-editor :value="response_body"></json-editor>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';
import prettyBytes from 'pretty-bytes';
import CodeEditor from 'vue3-code-editor';

// const { createHash } = require('crypto-js');
// import { createHash } from 'crypto-js';
import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
// import hmac from 'js-crypto-hmac';

// import { VAceEditor } from 'vue3-ace-editor';
// import 'ace-builds/src-noconflict/mode-text';
// import 'ace-builds/src-noconflict/theme-chrome';
// import { VAceEditor } from 'vue3-ace-editor';

import JsonEditor from 'vue-json-formatter-editor';

export default {
  props: {
    mode: {
      // default: 'view',
      default: 'edit',
      type: String,
    },
    modelValue: {
      type: Object,
    },
    // value: {
    //   type: Object,
    // },

  },
  components: {
    CodeEditor,
    JsonEditor,
    // VAceEditor,
  },
  data() {
    return {
      automaticAuthHeader: true,
      tab: 'header',
      tabResponse: 'body',
      methods: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
      method: null,
      sample: {
        id: 1,
        protocol: 'get',
        url: 'http://example.com',
        header: [
          {
            key: 'Content-Type',
            value: 'application/json',
            readonly: true,
            required: true,
            active: true,
          },
          {
            key: 'Auth',
            value: 'Bearer ',
            readonly: false,
            required: true,
            active: true,
          },
        ],
        body: null,
        active: true,
        createdAt: '2022-05-17T12:25:35.000Z',
        updatedAt: '2022-05-17T12:25:35.000Z',
        deletedAt: null,
        contentId: 2,
        headerTemplate: {
          key: null,
          value: null,
          readonly: false,
          required: true,
          active: true,
        },
      },
      showResponse: false,
      response: {
        status: null,
        time: null,
        size: null,
        body: '',
      },
      requestObj: {
        id: null,
        protocol: null,
        url: null,
        header: [],
        body: '',
        active: true,
        contentId: null,
      },
      sending: false,
      response_body: '',
    };
  },
  computed: {
    displayHeaders() {
      return this.requestObj.header ?? [];
    },
    displayVariables() {
      return this.requestObj.variables ?? [
        {
          key: 'Partner Key',
          value: 'uPIukMtZEeyd4gcivnhEqQ',
        },
        {
          key: 'Client ID',
          value: 'sv:v1:85401f70-cb5a-11ec-aaf7-a9776e8ad6d1',
        },
        {
          key: 'Secret Key',
          value: 'dqbzoKEKBYyXz4vDmIzDcxu4AzLmT0cEpHXNYhg4QqY',
        },
      ];
    },
  },
  methods: {
    init() {
      // eslint-disable-next-line global-require
      require('brace/theme/monokai');
      // eslint-disable-next-line global-require
      require('brace/mode/javascript');
      // eslint-disable-next-line global-require
      require('brace/mode/json');
    },
    updateResponseDetails(res) {
      this.response.status = res.status;
      this.response.time = res.totalTime;
      let { headers } = res;
      headers = Object.keys(res.headers).map((key) => ({ key, value: res.headers[key] }));
      this.response.headers = headers;
      const parsedData = JSON.stringify(res.data);
      this.response.body = parsedData;
      this.response_body = parsedData;

      this.response.size = prettyBytes(
        JSON.stringify(res.data).length
      + JSON.stringify(res.headers).length,
      );
    },
    updateRequestObj(data) {
      try {
        if (typeof (data) !== 'string') {
          return;
        }
        this.requestObj.body = data;
      } catch (e) {
        console.error(e);
      }
    },
    async handleSendButton() {
      const response = await this.sendRequest();
      this.updateResponseDetails(response);
    },
    async sendRequest() {
      const startTime = new Date().getTime();
      let result = {};

      try {
        this.showResponse = false;
        this.sending = true;
        const { requestObj } = this;
        const headers = (requestObj?.header) ? requestObj.header.reduce((item, pair) => {
          if (!pair || !pair.key) {
            return item;
          }
          return { ...item, [pair.key]: pair.value };
        }, {}) : null;

        result = await axios({
          url: requestObj.url,
          method: requestObj.protocol,
          headers,
          data: requestObj.body ?? null,
        });
      } catch (error) {
        result = error.response;
      } finally {
        const endTime = new Date().getTime();
        result.totalTime = endTime - startTime;
        this.showResponse = true;
        this.sending = false;
      }
      return result;
    },
    addHeader() {
      const headerTemplate = { ...this.headerTemplate };
      const { header } = this.requestObj;
      header.push(headerTemplate);
      this.requestObj.header = [...header];
      this.broadcastChange();
      // this.requestObj.header.push(headerTemplate);
    },
    async handleDeleteHeader(header) {
      const { requestObj } = this;
      const cleanHeaders = [...requestObj.header];
      cleanHeaders.splice(header, 1);
      // const cleanHeaders = requestObj.header.filter((el) => el !== header);
      // debugger;
      // const cleanHeaders = requestObj.header.splice(index, 1);
      this.requestObj.header = [...cleanHeaders];
      this.broadcastChange();
      // setTimeout(() => {
      // this.broadcastChange();
      // }, 5);
    },
    cleanHeaders() {
      const { requestObj } = this;
      if (!Array.isArray(requestObj?.header)) {
        requestObj.header = [];
      }
      const cleanHeaders = requestObj.header.filter((el) => (
        (el?.key?.trim().length > 0
        || el?.value?.trim().length > 0)
      ));
      this.requestObj.header = [...cleanHeaders];
    },
    handleHeaderChange() {
      // const { header } = this.requestObj;
      // if (!Array.isArray(header)) {
      //   return;
      // }
      // this.cleanHeaders();
      // this.addHeader();
    },
    broadcastChange() {
      this.$emit('update:modelValue', { ...this.requestObj });
    },
    hashBody(message = '') {
      if (!message) {
        return '';
      }
      const result = Base64.stringify(sha256(message));
      // result = Base64.stringify(result);
      return result;
    },
    normalizeRequest(nonce, requestMethod, url, host, port, bodyHash, ext) {
      let normalizedRequest = '';
      const NEW_LINE = '\n';
      normalizedRequest += nonce;
      normalizedRequest += NEW_LINE;
      normalizedRequest += requestMethod.toUpperCase().trim();
      normalizedRequest += NEW_LINE;
      normalizedRequest += url;
      normalizedRequest += NEW_LINE;
      normalizedRequest += host.toLowerCase();
      normalizedRequest += NEW_LINE;
      normalizedRequest += port.toString();
      normalizedRequest += NEW_LINE;
      normalizedRequest += bodyHash;
      normalizedRequest += NEW_LINE;
      normalizedRequest += ext;
      normalizedRequest += NEW_LINE;
      return normalizedRequest;
    },
    toBytes(text) {
      const surrogate = encodeURIComponent(text);
      const result = [];
      for (let i = 0; i < surrogate.length;) {
        const character = surrogate[i];
        i += 1;
        if (character === '%') {
          const hex = surrogate.substring(i, i += 2);
          if (hex) {
            result.push(parseInt(hex, 16));
          }
        } else {
          result.push(character.charCodeAt(0));
        }
      }
      return result;
    },
    async hashRequest(normalizedRequest, secretKey) {
      const signature = Base64.stringify(hmacSHA256(normalizedRequest, secretKey));
      return signature;
    },
    createHeaderValue(clientId, nonce, bodyHash, ext, hashedRequest) {
      let headerValue = '';
      headerValue += 'MAC id="';
      headerValue += clientId;
      headerValue += '",nonce="';
      headerValue += nonce;

      if (bodyHash) {
        headerValue += '",bodyhash="';
        headerValue += bodyHash;
      }

      if (ext) {
        headerValue += '",ext="';
        headerValue += ext;
      }

      headerValue += '",mac="';
      headerValue += hashedRequest;
      headerValue += '"';
      return headerValue;
    },
    async generateAuthHeader() {
      const {
        automaticAuthHeader,
        displayVariables,
        hashBody,
        normalizeRequest,
        hashRequest,
        createHeaderValue,
        requestObj,
      } = this;
      const { body, url } = requestObj;

      const header = requestObj.header.find((el) => el.key === 'AUTHORIZATION');

      if (header == null || automaticAuthHeader !== true || !Array.isArray(displayVariables)) {
        return;
      }
      // const partnerKey = displayVariables.find((el) => el.key === 'Partner Key')?.value;
      const clientId = displayVariables.find((el) => el.key === 'Client ID')?.value;
      const secretKey = displayVariables.find((el) => el.key === 'Secret Key')?.value;
      const nonce = `${new Date().getTime()}:vCZfJEjW`;
      // const nonce = '7349622:vCZfJEjW';

      const bodyHash = hashBody(body);

      // console.log(
      //   url,
      //   body,
      //   automaticAuthHeader,
      //   partnerKey,
      //   clientId,
      //   secretKey,
      //   nonce,
      //   bodyHash,
      // );
      // console.log(automaticAuthHeader, bodyHash);
      const urlObj = new URL(url);
      const { host, protocol } = urlObj;
      const port = (protocol === 'https:') ? 443 : 80;
      const path = urlObj.pathname;
      const ext = '';
      const requestMethod = requestObj.protocol.toUpperCase();
      // console.log(urlObj, host, port, requestMethod);
      // eslint-disable-next-line max-len
      const normalizedRequest = normalizeRequest(nonce, requestMethod, path, host, port, bodyHash, ext);
      const hashedRequest = await hashRequest(normalizedRequest, secretKey);
      // console.log(normalizedRequest, hashedRequest);
      console.log(normalizedRequest);
      const headerValue = createHeaderValue(clientId, nonce, bodyHash, ext, hashedRequest);
      console.log(headerValue);

      console.log(header);
      header.value = headerValue;
    },
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.showResponse = false;
      this.requestObj = newValue;
      // this.cleanHeaders();
      // this.addHeader();
    },
    automaticAuthHeader() {
      this.generateAuthHeader();
    },
    // requestObj: {
    //   handler() {
    //     this.broadcastChange();
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.init();
    // this.$refs.ceditor.setTheme("ace/monokai");
    this.requestObj = { ...this.modelValue };
    // this.requestObj = { ...this.sample };
    // this.requestObj.active = false;
    this.cleanHeaders();
    this.addHeader();
  },
};
</script>

<style lang="scss" scoped>
.request-builder-container {
  .readonlyheader {
    width: 87px;
  }
  .editor-container {
    // background-color: rgb(47, 49, 41);
    background-color: #272822;
    height: 500px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .send-button {
    height: 57px;
  }
  .header-detail-container {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: .5rem 2rem;
  }
}
</style>
