<template>
  <v-layout>

    <v-app-bar color="#222222" prominent>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title @click="goToHome" style="cursor: pointer;">
        <img :src="Logo" alt="" />
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu
        min-width="200px"
        rounded
        offset-y
        anchor="bottom end" origin="auto"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            v-bind="props"
          >
            <v-avatar
              color="brown"
              size="large"
            >
              <span class="white--text text-h5">{{ initials(user.username) }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar
                color="brown"
              >
                <span class="white--text text-h5">{{ initials(user.username) }}</span>
              </v-avatar>
              <h3>{{ user.username }}</h3>
              <p class="text-caption mt-1">
                Admin
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                rounded
                variant="text"
              >
                Edit Account
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                rounded
                variant="text"
                @click="logout"
              >
                Logout
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" bottom temporary>
      <v-list :items="items"></v-list>
    </v-navigation-drawer>

    <v-main elevation="0">
      <slot name="default"></slot>
    </v-main>
  </v-layout>
</template>

<script>
// import Logo from '@/assets/logo.svg';
import Logo from '@/assets/logo_sandia_white_ix0lrt.webp';

export default {
  name: 'SystemTemplate',
  data: () => ({
    Logo,
    user: JSON.parse(localStorage.getItem('auth')),
    drawer: false,
    items: [
      {
        title: 'Categories',
        value: 'categories',
        to: '/categories',
      },
    ],
  }),
  watch: {},
  methods: {
    initials(string) {
      const names = string.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    logout() {
      localStorage.removeItem('auth');
      this.$router.push({ path: '/login' });
    },
    goToHome() {
      this.$router.push({ path: '/dashboard' });
    },
  },
};
</script>

<style lang="scss">
.v-btn--icon .v-icon {
  color: $sandia-green;
}
.v-toolbar-title__placeholder {
  color: $sandia-green;
  display: grid;
  img {
    width: 100%;
    max-width: 190px;
  }
}
</style>
