<template>
  <default-template>
    <template #title>
      Documentation
    </template>

    <template #topnav>
      <v-btn
        v-for="category in docs"
        v-show="category.active === true"
        :key="category.uuid"
        variant="text"
        :to="`/docs/${getSlug(category.name)}`"
      >{{category.name}}</v-btn>
    </template>

    <template #sidenav>
      <v-list
        :items="sidenavItems.filter((el) => el.active === true)"
      ></v-list>
    </template>

    <template #default>
      <v-container fluid >

         <v-row justify="center" class="hidden-lg-and-up">
           <v-col xs="12" >
            <mobile-menu v-if="activeCategory" :item="activeCategory"></mobile-menu>
           </v-col>
         </v-row>
         <v-row justify="center" >
           <v-col xs="12" >
            <!-- category: <pre>{{category}}{{activeCategory}}</pre> -->
            <!-- subcategory: <pre>{{subcategory}}{{activeSubcategory}}</pre> -->
            <!-- subcategory: <pre>{{subcategory}}</pre>
             article: <pre>{{article}}{{activeArticle}}</pre>
             article: <pre>{{article}}</pre> -->
            <!-- article: <pre>{{article}}{{activeArticle}}</pre> -->
            <!-- category: <pre>{{category}}</pre>
            contents: <pre>{{renderContent}}</pre> -->
           </v-col>
         </v-row>

         <v-row justify="center">
           <v-col cols="12" xs="12" sm="12" md="6" lg="4"  class="hidden-md-and-down">
            <!-- categories -->
            <article-browser v-if="activeCategory" :item="activeCategory"></article-browser>
           </v-col>
           <v-col cols="12" xs="12" sm="12"
           :md="(code && code?.active === true) ? '6': '12'"
           :lg="(code && code?.active === true) ? '4': '6'" >
            <!-- document -->

            <v-card
              class="mx-auto"

            >
                  <template v-slot:title>
                    <v-container>
                      <v-row class="d-flex justify-center align-center">
                        <v-col>
                          {{renderContent?.active === true ? null : 'No content available'}}
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <template v-slot:text>
                    <!-- <pre>{{renderContent}}</pre> -->
                    <!-- {{renderContent?.active === true ? renderContent?.body : null}} -->
                    <!-- <pre>{{(code && code?.active === true) ? '6': '12'}}</pre> -->
                    <div
                      class="doc-content"
                      v-html="renderContent?.active === true ? renderContent?.body : null">
                    </div>
                  </template>
            </v-card>

           </v-col>
           <v-col xs="12" sm="12" md="6" lg="4" >
            <!-- code -->
            <v-card
              class="mx-auto"
              v-if="code && code?.active === true"
            >
            <template v-slot:text>
              <!-- <pre> {{renderContent?.codes?.[0]}}</pre> -->
              <!-- <pre> {{code}}</pre> -->
              <!-- {{renderContent?.codes?.[0].active ? renderContent?.codes?.[0] : 'code'}} -->
               <request-builder
                v-if="code && code?.active === true"
                :modelValue="code"
                mode="view"
              ></request-builder>
            </template>
            </v-card>
           </v-col>
         </v-row>

      </v-container>
    </template>
  </default-template>
</template>

<script>
import DefaultTemplate from '@/templates/DefaultTemplate.vue';
import MobileMenu from '@/components/MobileMenu.vue';
import ArticleBrowser from '@/components/ArticleBrowser.vue';
import RequestBuilder from '@/components/RequestBuilder.vue';

import API from '@/lib/Api';

export default {
  components: {
    DefaultTemplate,
    MobileMenu,
    ArticleBrowser,
    RequestBuilder,
  },
  props: [
    'category',
    'subcategory',
    'article',
  ],
  data() {
    return {
      docs: null,
      code: {},
      // activeCategory: null,
      // activeSubCategory: null,
      // activeArticle: null,
    };
  },
  watch: {
    renderContent(newValue) {
      this.code = newValue?.codes?.[0] ?? null;
      setTimeout(() => {
        document.querySelectorAll('pre, code').forEach((el) => {
        // eslint-disable-next-line no-undef
          hljs.highlightElement(el);
        }, 150);
      });
    },
  },
  methods: {
    async getDocs() {
      try {
        const { data } = await API.get('/categories');
        this.docs = data.rows;
      } catch (error) {
        console.error(error);
      }
    },
    getSlug(string) {
      if (typeof (string) !== 'string') {
        return '';
      }
      const clean = string.trim().toLowerCase().replaceAll(/ +/ig, '-');
      return clean;
    },
  },
  computed: {
    sidenavItems() {
      const { docs, getSlug } = this;
      if (!Array.isArray(docs)) {
        return [];
      }
      const result = docs.map((el) => ({
        title: el.name,
        value: el.name,
        active: el.active,
        to: `/docs/${getSlug(el.name)}`,
      }));
      return result;
    },
    activeCategory() {
      const {
        category,
        docs,
        getSlug,
      } = this;
      if (!Array.isArray(docs) || docs.length === 0) {
        return null;
      }
      if (category === null) {
        return docs[0];
      }
      const cat = docs.find((el) => getSlug(el.name) === category);
      return cat;
    },
    activeSubcategory() {
      const {
        activeCategory,
        subcategory,
        docs,
        getSlug,
      } = this;
      if (!Array.isArray(docs) || docs.length === 0) {
        return null;
      }
      // eslint-disable-next-line max-len
      if ((activeCategory == null || activeCategory.subcategories == null) && subcategory === null) {
        // return activeCategory.subcategory[0];
        return null;
      }
      const cat = activeCategory?.subcategories.find((el) => getSlug(el.name) === subcategory);
      return cat ?? null;
    },
    activeArticle() {
      const {
        activeSubcategory,
        article,
        docs,
        getSlug,
      } = this;
      if (!Array.isArray(docs) || docs.length === 0) {
        return null;
      }
      if ((activeSubcategory == null || activeSubcategory.articles == null) && article === null) {
        // return activeCategory.subcategory[0];
        return null;
      }
      const cat = activeSubcategory?.articles.find((el) => getSlug(el.title) === article);
      return cat ?? null;
    },
    renderContent() {
      const {
        activeCategory,
        activeArticle,
      } = this;
      // debugger;
      if (activeArticle == null && activeCategory?.contents) {
        return activeCategory?.contents?.[0] ?? null;
      }
      const content = activeArticle?.contents?.[0] ?? null;
      // const parsed = JSON.parse(JSON.stringify(content));
      // console.log(parsed);
      // debugger;
      return (content != null && content.active === true && content.body != null)
        ? content
        : null;
    },
    activeCode() {
      const {
        activeCategory,
        activeArticle,
      } = this;
      // debugger;
      if (activeArticle == null && activeCategory?.contents) {
        return activeCategory?.contents?.[0] ?? null;
      }
      const content = activeArticle?.contents?.[0] ?? null;
      // const parsed = JSON.parse(JSON.stringify(content));
      // console.log(parsed);
      // debugger;
      return (content != null && content.active === true && content.body != null)
        ? content
        : null;
    },
    // activeSubCategory: null,
    // activeArticle: null,
  },
  async beforeMount() {
    await this.getDocs();
    if (!Array.isArray(this.docs)) {
      return;
    }
    const category = this.docs[0];
    if (!this.category) {
      this.$router.push({ path: `/docs/${this.getSlug(category.name)}` });
    }
  },
};
</script>
<style lang="scss">
.doc-content{
  table, th, td {
    border: 1px solid #ddd;
    padding: 10px;
  }

  h1 {
    margin-bottom: 15px;
  }
  h2, h3 {
    margin-bottom: 10px;
  }
  p {
    margin: 10px 0;
  }
  code, pre {
    background-color: #2b2b2b;
    width: 100%;
    overflow: scroll;
    display: inline-block;
    padding: 15px;
    border-radius: 10px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

}
</style>
